<template>
  <div :class="openModal ? 'enter-active' : 'leave-active'" class="modal-container">
    <div class="background" @click="toggleLeaderboard" />
    <hotel-tech-awards-disclaimer v-if="!mouseOverTooltip" />
    <div class="modal-content">
      <div class="leaderBoardTitle">
        <p>
          <img src="/images/logo-without-text.svg" width="150" alt="HTR logo">
          <i class="icon-cross exitModal" @click="toggleLeaderboard" />
        </p>
        <p class="titleStyle">
          Live Leaderboard
        </p>
      </div>
      <div class="navbar-content">
        <div class="body-navbar">
          <div class="button-navbar">
            <a class="selectLi">Leaderboard</a>
          </div>
          <div class="button-navbar">
            <a href="https://partners.hoteltechreport.com/hotel-tech-awards/" target="_blank">About</a>
          </div>
          <div class="button-navbar">
            <a href="https://partners.hoteltechreport.com/hotel-tech-awards/" target="_blank">Key Dates</a>
          </div>
          <div class="button-navbar">
            <a href="https://help.hoteltechreport.com/en/articles/4382230-how-are-top-rated-category-winners-determined-in-the-awards" target="_blank">Scoring</a>
          </div>
          <div class="button-register-navbar buttonAsAlink">
            <a href="https://partners.hoteltechreport.com/hotel-tech-awards/" target="_blank" class="white-text">Register</a>
          </div>
        </div>
        <div class="awards-signed-alert">
          <i class="far fa-check-circle" />
          <span>
            {{ company.name }} has signed the
            <a href="https://help.hoteltechreport.com/en/articles/6493366-awards-rules-competition-guidelines" target="_blank">
              awards competition rules
            </a>
            . Make sure your team follows the rules to avoid penalties and disqualification.
          </span>
        </div>
        <div v-if="awardsEnabled" class="navbar-categories">
          <div v-for="category of categories" :key="category.id">
            <category :category="category" :company-slug="companySlug" @tooltip="mouseOverTooltip = $event" />
          </div>
        </div>
        <div v-else-if="awardsEnded" class="awardsEndedDiv flex flex-col items-center">
          <img width="166" height="110" src="/images/awardsend.svg">
          <h2 class="awardsEndedTitle">
            The awards period has ended, winners will be notified in January by email
          </h2>
          <p class="awardsEndedText">
            Since HTR is a live platform with new data coming in by the second, data is frozen and exported at the cutoff period in order to determine winners.
          </p>
          <p class="textAlignCenter">
            <a class="primary-btn-awards" href="https://help.hoteltechreport.com/en/articles/4646363-key-dates-rules-processes-leading-into-the-announcement-of-winners" target="_blank">Find out what happens next</a>
          </p>
        </div>
        <b-loading :is-full-page="false" :active="requestUpdateLoader" />
        <div v-if="leaderboards" class="award-final-stats">
          <div v-if="leaderboards.length > 0" class="award-final-stats-description">
            <h1 class="award-final-stats-title">
              Want to check your final stats?
            </h1>
            <p>
              View a summary of your final data and stats as of the deadline when the awards ended and data was frozen.
            </p>
          </div>
          <div v-for="product in leaderboards" :key="product.id" class="award-final-products">
            <div class="award-final-product-status-container">
              <span>
                {{ product.product_name }}
              </span>
              <div class="award-final-product-status">
                <span>
                  <i v-if="product.is_registered" class="far fa-check award-product-checked" />
                  <i v-else class="far fa-times award-product-not-checked" />
                  Registered
                </span>
                <span>
                  <i v-if="product.is_qualified" class="far fa-check award-product-checked" />
                  <i v-else class="far fa-times award-product-not-checked" />
                  Qualified
                </span>
              </div>
            </div>
            <div class="award-final-stats-button-container" :class="{ 'award-disabled-div' : !product.is_qualified }">
              <button class="award-final-stats-button" :class="{ 'award-disabled-button' : !product.is_qualified }" @click="openModalAwardEndMetrics(product)">
                View scorecard
              </button>
              <span v-if="!product.is_qualified" class="award-final-stats-discualified">
                Disqualified
              </span>
            </div>
          </div>
          <hotel-tech-awards-end-leaderboard-stats v-if="showModalAwardsEndMetrics" :product="productModal" @close="close" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.white-text {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}
.buttonAsAlink {
  color: white;
  background-color: #636CAE;
  justify-content: center;
  padding: calc(1em - 1px) 0.75em;

  border-radius: 2px;
  opacity: 1;

  max-height: 30px;
}
.awardsEndedDiv {
  text-align: center;
  margin-top: 30px;
  padding: 0px 58px 0px 58px;
}
.awardsEndedText {
  font-size: 12px;
  margin-top: 10px !important;
}
.awardsEndedTitle {
  font-weight: bold;
  font-size: 16px;
  margin-top: 29px;
  margin-bottom: 0px !important;
}
.awards-signed-alert {
  font-size: 10px;
  line-height: 12px;
  color: #9F9F9F;
  font-weight: 400;
  display: flex;
  padding: 18px 20px 0px 20px;
}
.textAlignCenter {
  text-align: center;
}
.modal-container {
  font-family: "Libre Franklin",sans-serif;
  transition: all 400ms ease;
  display: flex;
  flex-flow: row nowrap;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  position: fixed;
  z-index: 100000;
}
.background {
  width: 100%;
}
.exitModal {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 22px;
}
.leaderBoardTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.leaderBoardTitle img {
  margin-bottom: 5px;
}
.menuLeaderboard{
  display: flex;
}
.modal-content {
  transition: all 400ms ease;
  min-width: 471px;
  width: 471px;
  min-height: 100%;
  background-color: #f2f5fb;
  padding: inherit;
  font-size: 14px;
  overflow-y: auto;
  position: relative;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh);
}
.leave-active {
  z-index: -100000;
}
.enter-active {
  opacity: 1;
}
.leave-active {
  opacity: 0;
}
.titleStyle {
  color:#666FAB;
  font-weight: bold;
  font-size: x-large;
  margin: 0px;
  margin-bottom: 11px;
}
.body-navbar {
  display: flex;
  background-color: WHITE;
  justify-content: center;
  align-items: baseline;
}
.button-navbar {
  margin: 0px 6px;
  background-color: WHITE;
  position: relative;
}
.button-navbar a,
.button-navbar a:focus {
  padding: 13px 0px 10px 0px;
  font-size: 12px;
  display: block;
  color: #9E9E9E;
  border: none;
  background: none;
  letter-spacing: 0;
  font-weight: 500;
}
.button-navbar a:hover {
  background-color: WHITE;
  color: #202444 !important;
  border-bottom: 5px solid #636CAE !important;

  opacity: 1;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
}
.button-register-navbar {
  margin: 0px 6px;
  display: flex;
  align-items: center;
}
.fa-check-circle {
  margin-inline-end: 6px;
  font-size: 14px;
}
.primary-btn-awards {
  margin-top: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 188px;
  height: 33px;
  background: #4C5ECF;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
  font-weight: bold;
}
.selectLi {
  cursor: pointer;
  color: #202444 !important;
  font-weight: 700 !important;
  border-bottom: 5px solid #666FAB !important;
}

.navbar-categories {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 0px 20px;
}

.navbar-content .home__resources__item__inverted_link {
  margin: 0px;
  padding: 4px 8px;
  font-size: 14.5px;
  outline: none;
  height: auto;
  width: auto;
}
.navbar-content .home__resources__item__inverted_link:hover,
.navbar-content .home__resources__item__inverted_link:focus {
  background: white;
  color: #5761A8;
}
.award-final-stats {
  margin: 83px 58px 80px 58px;
}
.award-final-stats-title {
  color: #3445C2;
  font-size: 18px;
  font-weight: 600;
  margin-block-end: 4px;
}
.award-final-products {
  background-color: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 1px;
  margin-block-end: 4px;
  padding-block-start: 14px;
  padding-inline-start: 14px;
  padding-block-end: 8px;
  padding-inline-end: 21px;
  display: flex;
  justify-content: space-between;
  width: 358px;
  min-height: 65px;
  align-items: center;
}
.award-final-stats-description {
  margin-block-end: 18px;
  text-align: center;
  padding-inline-start: 18px;
  padding-inline-end: 17px;
  width: 358px;
}
.award-final-stats-button {
  background-color: #666FAB;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 600;
  width: 101px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.award-final-product-status {
  font-size: 10px;
  margin-block-start: 5px;
}
.award-final-product-status span{
  margin-inline-end: 5px;
}
.award-product-checked {
  color: #6EBD8C;
}
.award-product-not-checked {
  color: #E77964;
}
.award-final-stats-discualified {
  margin-block-start: 4px;
  color: #C23434;
  font-size: 10px;
  font-weight: 500;
}
.award-final-stats-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 21px;
}
.award-disabled-button {
  pointer-events: none;
  opacity: 0.16;
}
.award-disabled-div {
  cursor: not-allowed;
}
.award-final-product-status-container {
  width: 220px;
}
</style>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import Category from './Category.vue'
import eventBus from '../../../../dashboard/eventBus.js'
import HotelTechAwardsDisclaimer from './HotelTechAwardsDisclaimer.vue'
import HotelTechAwardsEndLeaderboardStats from './HotelTechAwardsEndLeaderboardStats.vue'
export default {
  components: {
    Category,
    HotelTechAwardsDisclaimer,
    HotelTechAwardsEndLeaderboardStats
  },
  props: [ 'categories', 'openModal', 'companySlug', 'company' ],
  data () {
    return {
      requestUpdateLoader: true,
      endDate: null,
      endDateMonth: null,
      productModal: null,
      activeLi: 'leaderBoard',
      mouseOverTooltip: false,
      leaderboards: null,
      showModalAwardsEndMetrics: false
    }
  },
  computed: {
    awardsEnabled () {
      return window.Laravel.awardsEnabled === 1
    },
    awardsEnded () {
      return window.Laravel.awardsEnabled === 2
    },
    awardsStep () {
      return window.Laravel.awardsStep
    },
    awardsEndDate () {
      return window.Laravel.awardsEndDate
    }
  },
  watch: {
    leaderboards () {
      if (!this.leaderboards) {
        this.requestUpdateLoader = true
      } else {
        this.requestUpdateLoader = false
      }
    }
  },
  created () {
    if (this.awardsStep < 3) {
      this.requestUpdateLoader = false
    }
    if (this.company.id && this.awardsStep >= 3) {
      this.fetchLeaderboardByCompanyId(this.company.id).then((res) => {
        if (res.data) {
          this.leaderboards = res.data
        }
      })
    }
    this.getDate()
  },
  methods: {
    ...mapActions([
      'fetchLeaderboardByCompanyId'
    ]),
    toggleLeaderboard () {
      eventBus.fire('toggleLeaderboard')
    },
    close () {
      this.showModalAwardsEndMetrics = false
    },
    openModalAwardEndMetrics (product) {
      this.productModal = product
      this.showModalAwardsEndMetrics = true
    },
    getDate () {
      if (this.awardsEndDate) {
        let endDateBeforeFormat = new Date(this.awardsEndDate + ' 00:00:00')
        this.endDate = moment(endDateBeforeFormat).format('DD')
        this.endDateMonth = new Date(this.awardsEndDate).toLocaleString('en-us', { month: 'short' })
      }
    }
  }
}
</script>
