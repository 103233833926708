<template>
  <div class="category">
    <div class="content-category">
      <div class="category-name">
        <div>
          <h3>{{ category.product.category_name }}</h3>
          <p>
            <i v-if="category.product.registered" class="fa fa-check" />
            <i v-else class="fa fa-times" />
            {{ category.product.registered ? 'Registered' : 'Not Registered' }}
            <span v-if="category.product.registered" class="emojis-left">
              <i v-if="category.product.reviews_count_current_year < minimumReviewCountForAwardsQualification" class="fa fa-times" />
              <i v-else class="fa fa-check" />
              {{ category.product.reviews_count_current_year >= minimumReviewCountForAwardsQualification ? 'Qualified' : 'Not Qualified' }}
            </span>
          </p>
        </div>
        <button class="minimize-button" @click="accordeon = !accordeon">
          {{ accordeon ? 'Minimize ' : 'View standings ' }}
          <i v-if="accordeon" class="fa fa-angle-up" />
          <i v-else class="fa fa-angle-down" />
        </button>
      </div>
      <div v-if="accordeon" class="category-products">
        <div v-if="category.top_products" class="have-products">
          <div v-for="product of topProductsAnalytics" :key="product.id" class="category-top-products">
            <div class="category-top-product-subcontainer">
              <div class="category-top-product-company">
                <p>{{ product.last_rank_in_category }}</p>
                <img :src="product.logo" alt="Product logo">
                <div>
                  <h5>{{ product.company_name }}</h5>
                  <p v-if="pointshidden">{{ Number(product.real_rank) }} points</p>
                </div>
              </div>
              <div class="category-product-rank">
                <img src="/images/logo-xs.svg" alt="HTR logo xs"> <p>{{ Math.floor(Number(product.rank)) ? Math.floor(Number(product.rank)) : 'Unranked' }}</p>
              </div>
            </div>
            <section class="category-stacked-bar">
              <chart-tooltip
                :ranktable="product.analytics.customer_ratings"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div
                  slot="hover"
                  class="stack-bar-customer-ranting stack-bar-container"
                  :style="{ width: product.analytics.customer_ratings.width + 'px'}"
                />
                <div slot="text">
                  <chart-table
                    :ranktable="product.analytics.customer_ratings"
                    :dot-color="{ color: '#5C68C3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="product.analytics.partner_ecosystem"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div
                  slot="hover"
                  class="stack-bar-partner stack-bar-container"
                  :style="{ width: product.analytics.partner_ecosystem.width + 'px' }"
                />
                <div slot="text">
                  <chart-table
                    :ranktable="product.analytics.partner_ecosystem"
                    :dot-color="{ color: '#9AEAD3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="product.analytics.customer_centricy"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div
                  slot="hover"
                  class="stack-bar-centricy stack-bar-container"
                  :style="{ width:product.analytics.customer_centricy.width + 'px' }"
                />
                <div slot="text">
                  <chart-table
                    :ranktable="product.analytics.customer_centricy"
                    :dot-color="{ color: '#D184E3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="product.analytics.reach_staying_power_resource"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div
                  slot="hover"
                  class="stack-bar-reach stack-bar-container"
                  :style="{ width:product.analytics.reach_staying_power_resource.width + 'px' }"
                />
                <div slot="text">
                  <chart-table
                    :ranktable="product.analytics.reach_staying_power_resource"
                    :dot-color="{ color: '#66ACFE' }"
                  />
                </div>
              </chart-tooltip>
            </section>
          </div>
          <div class="category-selected category-top-products">
            <div class="category-top-product-subcontainer">
              <div class="category-top-product-company">
                <p>{{ category.product.last_rank_in_category }}</p>
                <img :src="category.product.logo">
                <div>
                  <h5> You </h5>
                  <p v-if="pointshidden">{{ Number(category.product.real_rank) }} points</p>
                </div>
              </div>
              <div class="category-product-rank">
                <img src="/images/logo-xs.svg" alt="HTR logo xs"> <p>{{ Math.floor(Number(category.product.rank)) ? Math.floor(Number(category.product.rank)) : 'Unranked' }}</p>
              </div>
            </div>
            <section class="category-stacked-bar" style="padding-inline-start: 11px;padding-inline-end: 11px;">
              <chart-tooltip
                :ranktable="customerRatings"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div slot="hover" class="stack-bar-only-labels" :style="{ width:'auto' }">
                  <span class="dot" style="background-color: #5C68C3" />
                  <span class="title">Ratings</span>
                </div>
                <div slot="text">
                  <chart-table
                    :ranktable="customerRatings"
                    :dot-color="{ color: '#5C68C3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="partnerEcosystem"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div slot="hover" class="stack-bar-only-labels">
                  <span class="dot" style="background-color: #9AEAD3" />
                  <span class="title">Partners</span>
                </div>
                <div slot="text">
                  <chart-table
                    :ranktable="partnerEcosystem"
                    :dot-color="{ color: '#9AEAD3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="customerCentricy"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div slot="hover" class="stack-bar-only-labels">
                  <span class="dot" style="background-color: #D184E3" />
                  <span class="title">Customers</span>
                </div>
                <div slot="text">
                  <chart-table
                    :ranktable="customerCentricy"
                    :dot-color="{ color: '#D184E3' }"
                  />
                </div>
              </chart-tooltip>
              <chart-tooltip
                :ranktable="reachStayingPowerResource"
                :position-tooltip="{ top: '245px', right: '380px' }"
                @tooltip="mouseOverTooltip"
              >
                <div slot="hover" class="stack-bar-only-labels">
                  <span class="dot" style="background-color: #66ACFE" />
                  <span class="title">Reach</span>
                </div>
                <div slot="text">
                  <chart-table
                    :ranktable="reachStayingPowerResource"
                    :dot-color="{ color: '#66ACFE' }"
                  />
                </div>
              </chart-tooltip>
            </section>
          </div>
        </div>
        <div v-else class="without-products">
          <i class="far fa-lock-alt" />
          <p>Register this product in the HotelTechAwards to unlock the leaderboard and live coaching tips</p>
          <div class="buttonAsAlinkGreen">
            <a href="https://partners.hoteltechreport.com/hotel-tech-awards/" target="_blank" class="white-text">Register now</a>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="accordeon && category.top_products">
      <div>
        <p style="font-weight: 600">Want to move up in the rankings?</p>
        <div class="buttonAsAlink">
          <a href="#" class="white-text" @click="goToReportCard">View tips</a>
        </div>
      </div>
      <div v-if="category.product.reviews_count_current_year < minimumReviewCountForAwardsQualification" class="alert-category">
        <h5>This product is below the minimum eligibility requirement</h5>
        <p>In order to be eligible to win a HotelTechAward, you must get at least {{ minimumReviewCountForAwardsQualification }} reviews during the current awards period<br>({{ startDate }} - {{ endDate }})</p>
        <div>
          <div class="progress-bar-category">
            <div :style="{ width: reviewsProgressBar + '%' }" />
          </div>
          <p>{{ category.product.reviews_count_current_year }}/{{ minimumReviewCountForAwardsQualification }} reviews</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import chartTooltip from './ChartTooltip.vue'
import chartTable from './ChartTable.vue'
import eventBus from "../../../../dashboard/eventBus.js";

export default {
  components: { chartTooltip, chartTable },
  props: {
    category: {
      type: Object,
      default: function () {
        return { top_products: [], product: [] }
      }
    },
    companySlug: String
  },
  data () {
    return {
      accordeon: false,
      pointshidden: false,
      customerRatings: {},
      partnerEcosystem: {},
      customerCentricy: {},
      reachStayingPowerResource: {}
    }
  },
  computed: {
    minimumReviewCountForAwardsQualification () {
      if (window.Laravel && window.Laravel.minimumReviewCountForAwardsQualification &&
      Number(window.Laravel.minimumReviewCountForAwardsQualification) > 0) {
        return Number(window.Laravel.minimumReviewCountForAwardsQualification)
      }
      return 25
    },
    reviewsProgressBar () {
      const current = ((this.category.product.reviews_count_current_year / this.minimumReviewCountForAwardsQualification) * 100)
      return current > 100 ? 100 : current
    },
    startDate () {
      const format = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', timeZone:'UTC' })
      return format.format(new Date(window.Laravel.awardsStartDate || '2020/09/01'))
    },
    endDate () {
      const format = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', timeZone:'UTC' })
      return format.format(new Date(window.Laravel.awardsEndDate || '2020/12/15'))
    },
    topProductsAnalytics () {
      return this.category.top_products.map(function (product) {
        const percents = {
          customer_ratings: product.analytics.customer_ratings.relative_percent,
          partner_ecosystem: product.analytics.partner_ecosystem.relative_percent,
          customer_centricy: product.analytics.customer_centricy.relative_percent,
          reach_staying_power_resource: product.analytics.reach_staying_power_resource.relative_percent
        }
        product.analytics.customer_ratings.width = this.calculateWidth(percents, 'customer_ratings')
        product.analytics.partner_ecosystem.width = this.calculateWidth(percents, 'partner_ecosystem')
        product.analytics.customer_centricy.width = this.calculateWidth(percents, 'customer_centricy')
        product.analytics.reach_staying_power_resource.width = this.calculateWidth(percents, 'reach_staying_power_resource')

        return product
      }, this)
    },
    productAnalytics () {
      const product = this.category.product
      const percents = {
        customer_ratings: product.analytics.customer_ratings.relative_percent,
        partner_ecosystem: product.analytics.partner_ecosystem.relative_percent,
        customer_centricy: product.analytics.customer_centricy.relative_percent,
        reach_staying_power_resource: product.analytics.reach_staying_power_resource.relative_percent
      }
      product.analytics.customer_ratings.width = this.calculateWidth(percents, 'customer_ratings')
      product.analytics.partner_ecosystem.width = this.calculateWidth(percents, 'partner_ecosystem')
      product.analytics.customer_centricy.width = this.calculateWidth(percents, 'customer_centricy')
      product.analytics.reach_staying_power_resource.width = this.calculateWidth(percents, 'reach_staying_power_resource')

      return product
    }
  },
  watch: {
    category: {
      deep: true,
      handler (value) {
        if (this.category && this.category.product && this.category.product.analytics) {
          this.customerRatings = this.category.product.analytics.customer_ratings
          this.partnerEcosystem = this.category.product.analytics.partner_ecosystem
          this.customerCentricy = this.category.product.analytics.customer_centricy
          this.reachStayingPowerResource = this.category.product.analytics.reach_staying_power_resource
        } else {
          this.customerRatings = {}
          this.partnerEcosystem = {}
          this.customerCentricy = {}
          this.reachStayingPowerResource = {}
        }
      }
    }
  },
  mounted () {
    if (this.category && this.category.product && this.category.product.analytics) {
      this.customerRatings = this.category.product.analytics.customer_ratings
      this.partnerEcosystem = this.category.product.analytics.partner_ecosystem
      this.customerCentricy = this.category.product.analytics.customer_centricy
      this.reachStayingPowerResource = this.category.product.analytics.reach_staying_power_resource
    }
  },
  methods: {
    calculateWidth (percentage, type) {
      const width = 277
      const maxWidth = 0.3 * width
      const sum = 1 / (percentage.partner_ecosystem + percentage.customer_centricy + percentage.reach_staying_power_resource)
      switch (type) {
        case 'customer_ratings':
          const customerRatingMaxWidth = 0.7 * width
          const actualWidth = ((percentage.customer_ratings / 100) * width)
          return customerRatingMaxWidth > actualWidth ? actualWidth : customerRatingMaxWidth
        case 'partner_ecosystem':
          return (percentage.partner_ecosystem * sum) * maxWidth
        case 'customer_centricy':
          return (percentage.customer_centricy * sum) * maxWidth
        case 'reach_staying_power_resource':
          return (percentage.reach_staying_power_resource * sum) * maxWidth
      }
    },
    mouseOverTooltip (status) {
      this.$emit('tooltip', status)
    },
    goToReportCard () {
      this.$router.push({ name: 'reviews.reputation-overview' })
      eventBus.fire('toggleLeaderboard')
    }
  }
}
</script>
<style scoped>
  .minimize-button:hover {
    cursor: pointer;
  }
  .stack-bar-only-labels {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: relative;

    border-radius: 11px;
    border: 1px solid rgba(221,221,221, 1);
    opacity: 1;
    background-color: rgba(255,255,255, 1);
  }
  .dot {
    border-radius: 6px;
    width: 7px;
    height: 7px;
    opacity: 1;
    margin-left: 7px;
  }
  .title {
    opacity: 1;
    color: rgba(0,0,0,1);
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    margin: 3px 8px 4px 5px;
  }
  .white-text {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
  }
  .buttonAsAlink {
    color: white;
    background-color: #636CAE;
    justify-content: center;
    padding: calc(0.375em - 1px) 0.75em;

    border-radius: 2px;
    opacity: 1;
  }
  .buttonAsAlinkGreen {
    color: white;
    background-color: #6FBE8D;
    justify-content: center;
    padding: calc(0.375em - 1px) 0.75em;

    border-radius: 2px;
    opacity: 1;
  }
  .emojis-left {
    display: inline-block;
    margin-left: 8px;
  }
  .fa-times {
    color: #ff4c4c;
  }
  .fa-check {
    color: #28a745;
  }
  .fa-angle-up,
  .fa-angle-down {
    font-weight: bold;
  }
  .fa-lock {
    font-size: 29px;
    color: #9e9e9e;
  }
  .category {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }
  .content-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .category-name {
    display: flex;
    justify-content: space-between;
    padding: 12px 17px;
    width: 358px;
    z-index: 1;
    border-radius: 2px;
    flex-direction: row;
    align-items: center;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
    box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
    background: white;
  }
  .category-name div:first-child {
    display: flex;
    flex-direction: column;
  }
  .category-name div:first-child h3 {
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 600;
    color: #474747;
  }
  .category-name div:first-child p {
    margin: 2px 0px;
    color: #2A2A2A;
    font-weight: 400;
    font-size: 10px;
  }
  .category-name button {
    outline: none;
    background: none;
    border: none;
    font-size: 12px;
    font-weight: 600;
    color: #4A90E2;
    white-space: nowrap;
  }
  .category-name button:hover,
  .category-name button:focus {
    background: none;
  }
  .category-products {
    background: white;
    max-width: 340px;
    width: 340px;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
    box-shadow: 0px 0px 4px 1px rgba(209,209,209,1);
  }
  .category-stacked-bar {
    display: flex;
    padding-inline-start: 32px;
    padding-inline-end: 32px;
  }
  .category-stacked-bar:last-child {
    padding-block-end: 7px;
  }
  .category-stacked-bar {
    display: flex;
  }
  .category-top-products {
    display: grid;
  }
  .category-top-product-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-left: 5px solid transparent;
    padding-inline-start: 11px;
    padding-inline-end: 18px;
    padding-block-start: 13px;
    padding-block-end: 11px;
    align-items: center;
  }
  .category-top-product-company {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
  .category-top-product-company > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 500px;
  }
  .category-top-product-company img{
    background: #dcdcdc;
    margin-inline-end: 7px;
    width: 38px;
    height: 38px;
  }
  .category-top-product-company p {
    margin: 0;
    color: #C5C5C5;
    min-width: 17px;
    opacity: 1;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
  }
  .category-top-product-company h5 {
    margin: 0px;
    font-size: 12px;
    color: #474747;
    font-weight: 600;
    opacity: 1;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
  }

  .category-product-rank {
    display: flex;
    max-height: 58px;
    align-items: flex-end;
    min-width: 44px;
    align-content: center;
  }
  .category-product-rank  p {
    margin-inline-start: 4px;
    color: #ABABAB;
    font-size: 12px;
    opacity: 1;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
  }
  .category-product-rank img {
    height: 16px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
  }
  .category-selected {
    border-left: 5px solid #5761A8 !important;
    background: rgba(87,97,168,0.07);
  }
  .category-selected > div div:last-child {
    color: #5761A8 !important
  }
  .category-selected div div:last-child img {
    filter: none !important;
    opacity: 1 !important;
  }
  .without-products {
    padding: 23px 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .without-products p {
    margin: 8px;
    opacity: 1;
    color: #989898;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
  }
  footer {
    display: flex;
    margin-top: 10px !important;
    flex-direction: column;
  }
  footer > div:first-child {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  footer > div:first-child p {
    color: #666FAB;
    margin: 0px 13px 0px 0px;
    font-weight: 600;
    opacity: 1;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
  }
  .alert-category {
    padding: 15px 25px;
    background: #ffeaea;
    margin-top: 20px;
  }
  .alert-category h5 {
    font-weight: 700;
    color: #E77964;
    margin: 0px;
    font-size: 12px;
  }
  .alert-category > p {
    font-weight: 400;
    margin: 4px 0px 11px 0px;
    color: #E77964;
    font-size: 12px;
  }
  .alert-category > div {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .alert-category > div p {
    margin: 0px;
    margin-left: 8px;
    color: #E77964;
    white-space: nowrap;
    font-size: 12px;
  }
  .progress-bar-category {
    width: 74px;
    display: flex;
    justify-content: flex-start;
    height: 13px;

    border-radius: 4.5px;
    opacity: 1;
    background-color: rgba(230,230,230, 1);
  }
  .progress-bar-category div {
    height: 100%;
    border-radius: 4.5px;
    opacity: 1;
    background-color: rgba(231,121,100, 1);
  }
  .button-success {
    background: #28a745;
    border: 1px solid #28a745;
  }
  .button-success:hover,
  .button-success:focus {
    background: none;
    color: #28a745 !important;
  }

  .navbar-content .home__resources__item__inverted_link {
    margin: 0px;
    padding: 4px 8px;
    font-size: 14.5px;
    outline: none;
    height: auto;
    width: auto;
  }
  .navbar-content .home__resources__item__inverted_link:hover,
  .navbar-content .home__resources__item__inverted_link:focus {
    background: white;
    color: #5761A8;
  }
.stack-bar-customer-ranting {
  background-color: #5C68C3;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}
.stack-bar-partner {
  background-color: #9AEAD3;
}
.stack-bar-centricy {
  background-color: #D184E3;
}
.stack-bar-reach {
  background-color: #66ACFE;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.stack-bar-container {
  height: 20px;
}
</style>
