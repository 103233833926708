<template>
  <div class="contentTooltip">
    <div
      class="textWithTooltip"
      @mouseover="changeTooltip(true)"
      @mouseleave="changeTooltip(false)"
    >
      <slot name="hover" />
    </div>
    <div
      v-if="tooltip && showTooltip"
      class="chart-analytics-container"
      :style="{
        '--top-tooltip': positionTooltip.top,
        '--bottom-tooltip': positionTooltip.bottom,
        '--left-tooltip': positionTooltip.left,
        '--right-tooltip': positionTooltip.right
      }"
      @mouseover="changeTooltip(true)"
      @mouseleave="changeTooltip(false)"
    >
      <slot name="text" />
    </div>
  </div>
</template>
<style scoped>
.contentTooltip:hover {
  cursor: pointer;
}
.contentTooltip {
  display: inline-block;
  position: relative;
}
.chart-analytics-container {
  width: 295px;
  background-color: white;
  border: 1px solid #F3F6FF;
  border-radius: 4px;
  padding-inline-start: 15px;
  padding-block-start: 19px;
  padding-inline-end: 26px;
  padding-block-end: 11px;
  position: fixed;
  word-wrap: break-word;
  top: var(--top-tooltip);
  bottom: var(--bottom-tooltip);
  left: var(--left-tooltip);
  right: var(--right-tooltip);
  overflow: var(--overflow-tooltip);
  z-index: 100001;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0, 0.17);
}
</style>
<script>
export default {
  props: {
    showTooltip: {
      default: true
    },
    positionTooltip: {
      default: {
        top: 'auto',
        bottom: 'auto',
        left: 'auto',
        right: 'auto'
      }
    }
  },
  data () {
    return {
      tooltip: false
    }
  },
  methods: {
    changeTooltip (newStatus) {
      this.$emit('tooltip', newStatus)
      this.tooltip = newStatus
    }
  }
}
</script>
