<template>
  <div class="modal-awards-metrics-container">
    <div class="background" @click="closeModalAwards" />
    <div class="modal-awards-metrics-content">
      <button class="closeModal award-close-modal">
        <img src="/images/close_gray.svg" @click="closeModalAwards">
      </button>
      <div class="modal-awards-content-container">
        <div>
          <i class="far fa-calculator modal-awards-calculator" />
          <p class="modal-awards-title">
            {{ yearAward ? yearAward : '' }} HotelTechAwards Final Scorecard for {{ product.product_name ? product.product_name : '' }} as of the Cutoff
          </p>
        </div>
        <div v-if="product && product.data" class="modal-awards-table-container">
          <div class="modal-awards-subtable">
            <div class="modal-awards-customer-rantings-table">
              <chart-table
                :ranktable="product.data.customer_ratings"
                :dot-color="{ color: '#5C68C3' }"
              />
            </div>
            <div class="modal-awards-customer-ecosytem-table">
              <chart-table
                :ranktable="product.data.partner_ecosystem"
                :dot-color="{ color: '#9AEAD3' }"
              />
            </div>
          </div>
          <div>
            <div class="modal-awards-customer-customer-table">
              <chart-table
                :ranktable="product.data.customer_centricy"
                :dot-color="{ color: '#D184E3' }"
              />
            </div>
            <div class="modal-awards-customer-staying-table">
              <chart-table
                :ranktable="product.data.reach_staying_power_resource"
                :dot-color="{ color: '#66ACFE' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.closeModal {
  position: absolute;
  top: 14px;
  right: 10px;
  border: none;
  background: none;
  padding: 0;
  margin-inline-end: 5px;
  cursor: pointer;
}
.modal-awards-metrics-content {
  height: auto;
  width: 714px;
  background-color: #FFFFFF;
  padding-block-start: 76px;
  padding-block-end: 33px;
  padding-inline-start: 31px;
  padding-inline-end: 32px;
  position: relative;
  text-align: center;
  font-size: 12px;
  border-radius: 8px;
}
.modal-awards-calculator {
  color: #4454B8;
  font-size: 50px;
  font-weight: 400;
  margin-block-end: 13px;
}
.modal-awards-title {
  color: #3E4D60;
  font-size: 22px;
  font-weight: 700;
}
.modal-awards-customer-rantings-table {
  width: 295px;
  height: 379px;
  border-radius: 4px;
  border: 1px solid #F3F6FF;
  box-shadow: 0 2px 6px 0px rgb(0, 0, 0, 0.17);
  margin-block-end: 16px;
  padding: 19px 21px 27px 18px;
}
.modal-awards-customer-ecosytem-table {
  width: 295px;
  height: 203px;
  border-radius: 4px;
  border: 1px solid #F3F6FF;
  box-shadow: 0 2px 6px 0px rgb(0, 0, 0, 0.17);
  padding: 19px 21px 27px 18px;
}
.modal-awards-customer-customer-table {
  width: 295px;
  border-radius: 4px;
  border: 1px solid #F3F6FF;
  box-shadow: 0 2px 6px 0px rgb(0, 0, 0, 0.17);
  margin-block-end: 13px;
  padding: 19px 21px 27px 18px;
}
.modal-awards-customer-staying-table {
  width: 295px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #F3F6FF;
  box-shadow: 0 2px 6px 0px rgb(0, 0, 0, 0.17);
  padding: 19px 21px 27px 18px;
}
.modal-awards-table-container {
  margin-block-start: 34px;
  display: flex;
  justify-content: center;
}
.modal-awards-subtable {
  margin-inline-end: 16px ;
}
</style>
<script>
import chartTable from './ChartTable.vue'
import moment from 'moment'
export default {
  components: {
    chartTable
  },
  props: [ 'product' ],
  data () {
    return {
      endDateDay: 0,
      endDateMonth: 0,
      yearAward: 0
    }
  },
  mounted () {
    this.getDate()
  },
  methods: {
    closeModalAwards () {
      this.$emit('close')
    },
    getDate () {
      if (this.product && this.product.end_awards_at) {
        let endDateBeforeFormat = new Date(this.product.end_awards_at + ' 00:00:00')
        this.endDateDay = moment(endDateBeforeFormat).format('DD')
        this.endDateMonth = new Date(this.product.end_awards_at).toLocaleString('en-us', { month: 'short' })
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let day = new Date().getDate()
        this.yearAward = new Date(year + 1, month, day).toLocaleString('en-us', { year: 'numeric' })
      }
    }
  }
}
</script>
