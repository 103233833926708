<template>
  <div>
    <div class="chart-table-title">
      <i class="fas fa-circle" :style="{ '--dot-color': computedColor.color }" />
      <span>
        {{ computedTable.title }}
      </span>
    </div>
    <table v-for="analitic in computedTable.data" :key="analitic.id" class="rank-sub-container">
      <tr class="rank-sub-container">
        <th class="rank-sub-title">
          <span>
            {{ analitic.title }}
          </span>
        </th>
      </tr>
      <tr v-for="subrank in analitic.data" :key="subrank.id" class="rank-table-row">
        <td class="rank-table-title">
          <span>
            {{ subrank.title }}
          </span>
        </td>
        <td class="rank-table-value">
          <span>
            {{ subrank.data }}
          </span>
        </td>
      </tr>
    </table>
  </div>  
</template>
<style scoped>
  .chart-table-title {
    font-size: 14px;
    font-weight: 600;
    color: #3E4D60;
    margin-block-end: 21px;
    display: flex;
    align-items: center;
  }
  .fa-circle {
    color: var(--dot-color);
    font-size: 9px;
    margin-inline-end: 4px;
  }
  .rank-table-row:nth-child(even) {
    background-color: #F3F4F9;
  }
  .rank-table-row {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rank-table-title {
    padding-inline-start: 5px;
  }
  .rank-table-value {
    text-align: end;
    padding-inline-end: 6px;
  }
  .rank-sub-container {
    color: #3E4D60;
    font-size: 12px;
    margin-block-end: 16px;
    width: 100%;
  }
  .rank-sub-container table{
    width: 100%;
  }
  .rank-sub-title {
    font-weight: 600;
    padding-inline-start: 5px;
    margin-block-end: 1px;
  }
</style>
<script>
export default {
  props: [
    'ranktable',
    'dotColor'
  ],
  computed: {
    computedColor() {
      return this.dotColor
    },
    computedTable() {
      return this.ranktable
    }
  }
}
</script>